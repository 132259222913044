import {useQuery, useMutation} from 'graphql/client';
import ClientOrganizationCachedSignals from 'graphql/pages/signals/queries/ClientOrganizationCachedSignals.graphql';
import GenerateCachedSignalQuery from 'graphql/pages/signals/mutations/GenerateCachedSignal.graphql';
import DeleteAllCachedSignalsQuery from 'graphql/pages/signals/mutations/DeleteAllCachedSignals.graphql';

import {
    ClientOrganizationCachedSignalsPayload,
    QueryClientOrganizationCachedSignalsArgs,
    CachedSignal,
    GenerateCachedSignalPayload,
    MutationGenerateCachedSignalArgs,
    MutationDeleteAllCachedSignalsArgs,
    DeleteCachedSignalPayload,
} from 'src/types/graphql-types';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {useSessionStorage} from 'components/hooks/useSessionStorage';
import User from 'core/User';

export const MAXIMUM_SIGNALS = 6;

function useClientOrganizationCachedSignalsQuery(
    clientOrganizationId: string,
    user: User,
    signalTypeDescription = ''
): {
    signals: CachedSignal[];
    loading: boolean;
    sessionCachedSignal: CachedSignal;
    setSessionCachedSignal: Dispatch<SetStateAction<CachedSignal>>;
    clearSessionCachedSignal: () => void;
    deleteAllCachedSignals: () => Promise<{
        deleteAllCachedSignals: DeleteCachedSignalPayload;
    }>;
} {
    const {data, loading: cachedSignalsLoading} = useQuery<
        {
            clientOrganizationCachedSignals: ClientOrganizationCachedSignalsPayload;
        },
        QueryClientOrganizationCachedSignalsArgs
    >(ClientOrganizationCachedSignals, {
        variables: {
            clientOrganizationId,
        },
        fetchPolicy: 'network-only',
    });

    const [
        generateCachedSignal,
        {loading: generateCachedSignalLoading, data: generateCachedSignalData},
    ] = useMutation<
        {generateCachedSignal: GenerateCachedSignalPayload},
        MutationGenerateCachedSignalArgs
    >(GenerateCachedSignalQuery, {
        variables: {
            clientOrganizationId,
            signalDescriptionSearchText: signalTypeDescription,
        },
        refetchQueries: [ClientOrganizationCachedSignals],
    });

    const [deleteCachedSignals, {loading: deleteAllCachedSignalsLoading}] =
        useMutation<
            {deleteAllCachedSignals: DeleteCachedSignalPayload},
            MutationDeleteAllCachedSignalsArgs
        >(DeleteAllCachedSignalsQuery, {
            variables: {
                clientOrganizationId,
            },
            refetchQueries: [ClientOrganizationCachedSignals],
        });

    const deleteAllCachedSignals = async () => {
        const result = await deleteCachedSignals();
        if (!result.data) {
            throw new Error('Failed to delete cached signals');
        }
        return result.data;
    };

    useEffect(() => {
        if (
            data?.clientOrganizationCachedSignals?.cachedSignals?.length <
                MAXIMUM_SIGNALS &&
            (user.clientOrganization.hasContextProfileAnswers ||
                signalTypeDescription)
        ) {
            generateCachedSignal().catch(() => null);
        }
    }, [
        data,
        generateCachedSignal,
        generateCachedSignalData,
        user,
        signalTypeDescription,
    ]);

    const cachedSignals =
        data?.clientOrganizationCachedSignals?.cachedSignals ?? [];

    const {
        sessionValue: sessionCachedSignal,
        setSessionValue: setSessionCachedSignal,
    } = useSessionStorage('cachedSignal', null);

    return {
        signals: cachedSignals,
        loading:
            cachedSignalsLoading ||
            generateCachedSignalLoading ||
            deleteAllCachedSignalsLoading,

        sessionCachedSignal,
        setSessionCachedSignal,
        clearSessionCachedSignal: () => setSessionCachedSignal(null),
        deleteAllCachedSignals,
    };
}

export {useClientOrganizationCachedSignalsQuery};
