import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {logger} from 'utils/logger';

export const useSessionStorage = <T>(
    key: string,
    initialValue: T
): {sessionValue: T; setSessionValue: Dispatch<SetStateAction<T>>} => {
    const [sessionValue, setSessionValue] = useState<T>(() => {
        if (typeof window === 'undefined') {
            return initialValue;
        }

        try {
            const item = window.sessionStorage.getItem(key);
            return (item ? JSON.parse(item) : initialValue) as T;
        } catch (error) {
            logger.warn(`Error reading session storage key \`${key}\`.`);
            return initialValue;
        }
    });

    useEffect(() => {
        window.sessionStorage.setItem(key, JSON.stringify(sessionValue));
    }, [key, sessionValue]);

    return {sessionValue, setSessionValue};
};
