import {useMutation} from 'graphql/client';
import {
    ApolloError,
    FetchResult,
    MutationFunctionOptions,
} from '@apollo/client';
import DeleteCachedSignalMutation from 'graphql/pages/signals/mutations/DeleteCachedSignal.graphql';
import ClientOrganizationCachedSignalsQuery from 'graphql/pages/signals/queries/ClientOrganizationCachedSignals.graphql';
import {
    DeleteCachedSignalPayload,
    MutationDeleteCachedSignalArgs,
} from 'src/types/graphql-types';

function useDeleteCachedSignalMutation({
    clientOrganizationId,
    cachedSignalId,
}: {
    clientOrganizationId: string;
    cachedSignalId: string;
}): {
    deleteCachedSignalMutation: (
        options?: MutationFunctionOptions<
            {deleteCachedSignal: DeleteCachedSignalPayload},
            MutationDeleteCachedSignalArgs
        >
    ) => Promise<FetchResult<unknown>>;
    loading: boolean;
    error: ApolloError | undefined;
} {
    const [deleteCachedSignalMutation, {loading, error}] = useMutation<
        {deleteCachedSignal: DeleteCachedSignalPayload},
        MutationDeleteCachedSignalArgs
    >(DeleteCachedSignalMutation, {
        variables: {clientOrganizationId, cachedSignalId},
        refetchQueries: [
            {
                query: ClientOrganizationCachedSignalsQuery,
                variables: {clientOrganizationId},
            },
        ],
    });

    return {
        deleteCachedSignalMutation,
        loading,
        error,
    };
}

export {useDeleteCachedSignalMutation};
